	import React from 'react';
	import ContentBody from './contentBody';
	import {Link} from "react-router-dom";
	import Footer from './footer';


	function Pressrelease() {
	
	const marginLeft = {
		marginLeft: '5px',
		width: '180px'
	}
	return (
		<>
			<div className="pressrelease">
				<div style={marginLeft}>
				<Link to="/" className="navbar-brand-about">
					<div className="logo dark"></div>
				</Link>
				</div>
				<ContentBody page="pressrelease25th" />
			</div>
			<Footer page="pressrelease25th"/>
		</>
	);
	}

	export default Pressrelease;
