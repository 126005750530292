
const initialState = {
    showModal : false,
    msg : '',
    showError : false,
    showLoading : false,
    language: 'English'
}

const reducer = (state = initialState, action) => {
    
    switch(action.type){
        case 'OPEN':
            return{
                showModal : true,
                msg : '',
                showError : true,
                showLoading : false,
                language: action.value
            };
        case 'CLOSE':
            return{
                showModal : false,
                msg : '',
                showError : false,
                showLoading : false,
                language: action.value
            };
        case 'SHOW_MESSAGE':
            return{
                showModal : true,
                msg : action.value,
                showError : action.error,
                showLoading : false,
                language: action.language
            };
        case 'SHOW_LOADING':
            return{
                showLoading : true,
                language: action.language
            };
        case 'ENGLISH':
            return{
                showModal : false,
                language : 'Français'
            };
        case 'FRENCH':
            return{
                showModal : false,
                language : 'English'
            };
        default:
            return state
    }
};


export default reducer;