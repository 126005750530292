import React from 'react';
import {connect } from 'react-redux';

const Whatwedo = (props) => {

    const hideFrench = {
        display : (props.language === 'English') ? "none" : "block"
    }
    const hideEnglish = {
        display : (props.language === 'English') ? "block" : "none"
    }

    return ( 
        <div className="what-to-do-section-container" id="our_products">
            <div className="what-to-do-section" style={hideFrench}>
                <div className="mission bluecolored">our products</div>
                <div className="title">What We Do</div>
                <div className="description">Visicom Media is a leading developer focused on developing innovative tools, software applications, and solutions that enhance user experience and empower brands while increasing revenue opportunities.</div>
            </div>
            <div className="what-to-do-section" style={hideEnglish}>
                <div className="mission bluecolored">Nos produits</div>
                <div className="title">Notre cœur de métier</div>
                <div className="description">Visicom Media est un développeur de premier plan axé sur le développement d'outils, d'applications logicielles et de solutions à la pointe de l'innovation qui améliorent l'expérience utilisateur et donnent aux marques les moyens de réussir tout en augmentant les opportunités de revenus.</div>
            </div>
        </div>
     );
}
 
const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Whatwedo);