import React, { Component } from 'react';
import {connect } from 'react-redux';
import loadinglogo from '../assets/images/ajax-loader.gif'

class SearchSolution extends Component {
    constructor(props) {
        super();
        this.state = {
            name : '',
            company : '',
            email : '',
            country : '',
            url : '',
            monthly_traffic : '',
            geomarket : '',
            showMsg : false,
            showError : false,
            msg: ''
        }
    }

    inputNameHandler = (event) => {
        this.setState({name : event.target.value});
    }
    inputCompanyHandler = (event) => {
        this.setState({company : event.target.value});
    }
    inputEmailHandler = (event) => {
        this.setState({email : event.target.value});
    }
    inputCountryHandler = (event) => {
        this.setState({country : event.target.value});
    }
    inputUrlHandler = (event) => {
        this.setState({url : event.target.value});
    }
    inputMonthlyTrafficHandler = (event) => {
        this.setState({monthly_traffic : event.target.value});
    }
    inputGeomarketHandler = (event) => {
        this.setState({geomarket : event.target.value});
    }
   
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({showError : false});
        if(this.state.name === ''){
            this.setState({showMsg: true});
            const name_msg = (this.props.language !== 'English') ? 'Please provide with a valid name' : 'Veuillez fournir un non valide';
            this.props.onShowMessage(name_msg, true, this.props.language);

        }else if(this.state.company === ''){
            this.setState({showMsg: true});
            const company_msg = (this.props.language !== 'English') ? 'Please provide with a valid company name' : 'Veuillez fournir un non de compagnie valide';
            this.props.onShowMessage(company_msg, true, this.props.language);

        }else if(this.state.email === ''){
            this.setState({showMsg: true});
            const email_msg = (this.props.language !== 'English') ? 'Please provide with a valid email' : 'Veuillez fournir un courriel valide';
            this.props.onShowMessage(email_msg, true, this.props.language);
        }
        else if(this.state.country === ''){
            this.setState({showMsg: true});
            const country_msg = (this.props.language !== 'English') ? 'Please provide with a valid country' : 'Veuillez fournir un pays valide';
            this.props.onShowMessage(country_msg, true, this.props.language);
        }
        else{
            this.props.onShowLoading(this.props.language);
            const endpoint = 'https://betatest.vmn.net/send_mail_contact/index.php';
            const data = {
                name:this.state.name,
                company : this.state.company,
                email : this.state.email,
                country : this.state.country,
                url : this.state.url,
                monthly_traffic : this.state.monthly_traffic,
                geomarket : this.state.geomarket,
                lg : this.props.language
            }
            fetch(endpoint, {
                method : "POST",
                body : JSON.stringify(data)
            })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if(response.action === "failed"){
                    this.props.onShowMessage(response.msg, true, this.props.language);
                }else{
                    this.setState({
                        name : '',
                        company : '',
                        email : '',
                        country : '',
                        url : '',
                        monthly_traffic : '',
                        geomarket : ''
                    })
                    this.props.onShowMessage(response.msg, false, this.props.language);
                }
            })
            .catch(err => {
                this.props.onShowMessage(err, true, this.props.language);
            });
        }
    }

    closeSearchSolution = () => {
        this.props.onCloseModal(this.props.language)
    }

    render(props) { 
        
        const showModalStyle = {
            display : (this.props.modalShow === false) ? "none" : "block"
        }

        const showErrorStyle = {
            display : (this.props.showMsg === false) ? "none" : "block"
        }

        const msgClassName = (this.props.showError === false) ? "msg" : "msg error";
        const showLoading = {
            display : (this.props.showLoading === false) ? "none" : "block"
        }
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }
        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }
        return ( 
            <section className="ss-overlay" style={showModalStyle} value={this.props.modalShow}>
                <div className="searchsolution">
                    <div className="close" onClick={this.closeSearchSolution}></div>
                    <div className="info">
                        <div className="providers"></div>
                        <div className="questions" style={hideFrench}>Do you want to drive more revenue to your site or mobile applications?</div>
                        <div className="questions" style={hideEnglish}>Voulez-vous générer plus de revenus sur votre site ou vos applications mobiles ?</div>
                        <div className="title" style={hideFrench}>PARTNER WITH US!</div>
                        <div className="title" style={hideEnglish}>PARTNERAIRES AVEC NOUS!</div>
                        <div className="lists">
                            
                            <div className="list">
                                <div className="liststyle"> </div>
                                <div className="text" style={hideFrench}>
                                We work with you on the look and feel optimizing the landing page experience.
                                </div>
                                <div className="text" style={hideEnglish}>
                                Nous travaillons avec vous sur l'aspect et la convivialité pour optimiser l'expérience de la page d'accueil.
                                </div>
                            </div>
                            <div className="list">
                                <div className="liststyle"> </div>
                                <div className="text" style={hideFrench}>
                                    Through the Yahoo! Bing Network publishers can display on their website or mobile applications high targeted, relevant ads.
                                </div>
                                <div className="text" style={hideEnglish}>
                                    Grâce à Yahoo! Les éditeurs du réseau Bing peuvent afficher sur leur site Web ou leurs applications mobiles des publicités très ciblées et pertinentes.
                                </div>
                            </div>
                            <div className="list">
                                <div className="liststyle"> </div>
                                <div className="text" style={hideFrench}>
                                We offer various type of implementations that fit your niche and serve ads relevant to what your users are searching for.
                                </div>
                                <div className="text" style={hideEnglish}>
                                Nous proposons différents types d'implémentations qui correspondent à votre créneau et diffusons des publicités en rapport avec ce que recherchent vos utilisateurs.
                                </div>
                            </div>
                        </div>
                        <div className="bottomtxt" style={hideFrench}>Make more money with us!</div>
                        <div className="bottomtxt" style={hideEnglish}>Gagnez plus d'argent avec nous!</div>
                    </div>
                    <div className="form">
                        <form onSubmit={this.handleSubmit} style={hideFrench}>
                            <div className="tellus" >Tell us about you and we will contact you within one business day</div>
                            <div className="loading" style={showLoading}><img src={loadinglogo} alt="loading"/></div>
                            <div className={msgClassName} style={showErrorStyle}>{this.props.msg}</div>
                            <input type="text" name="name" id="name" placeholder="Name *" value={this.state.name} onChange={this.inputNameHandler}/>
                            <input type="text" name="company" id="company" placeholder="Company *" value={this.state.company} onChange={this.inputCompanyHandler}/>
                            <input type="text" name="email" id="email" placeholder="Email *" value={this.state.email} onChange={this.inputEmailHandler}/>
                            <input type="text" name="country" id="country" placeholder="Country *" value={this.state.country} onChange={this.inputCountryHandler} />
                            <input type="text" name="url" id="url" placeholder="URL where our Yahoo! ads will be displayed" value={this.state.url} onChange={this.inputUrlHandler}/>
                            <input type="text" name="monthly_traffic" id="monthly_traffic" placeholder="Monthly traffic" value={this.state.monthly_traffic} onChange={this.inputMonthlyTrafficHandler}/>
                            <input type="text" name="geomarket" id="geomarket" placeholder="Your top 3 geo market" value={this.state.geomarket} onChange={this.inputGeomarketHandler}/>
                            <div className="submit" type="submit" name="submit" onClick={this.handleSubmit}>Submit</div>
                        </form>
                        <form onSubmit={this.handleSubmit} style={hideEnglish}>
                            <div className="tellus">Parlez-nous de vous et nous vous contacterons dans un délai d'un jour ouvrable</div>
                            <div className="loading" style={showLoading}><img src={loadinglogo} alt="loading"/></div>
                            <div className={msgClassName} style={showErrorStyle}>{this.props.msg}</div>
                            <input type="text" name="name" id="Name" placeholder="Nom *" value={this.state.name} onChange={this.inputNameHandler}/>
                            <input type="text" name="company" id="compagny" placeholder="Compagnie *" value={this.state.company} onChange={this.inputCompanyHandler}/>
                            <input type="text" name="email" id="email" placeholder="Courriel *" value={this.state.email} onChange={this.inputEmailHandler}/>
                            <input type="text" name="country" id="country" placeholder="Pays *" value={this.state.country} onChange={this.inputCountryHandler} />
                            <input type="text" name="url" id="url" placeholder="URL où nos annonces Yahoo! seront affichées" value={this.state.url} onChange={this.inputUrlHandler}/>
                            <input type="text" name="monthly_traffic" id="monthly_traffic" placeholder="Trafic mensuel" value={this.state.monthly_traffic} onChange={this.inputMonthlyTrafficHandler}/>
                            <input type="text" name="geomarket" id="geomarket" placeholder="Votre top 3 du marché géographique" value={this.state.geomarket} onChange={this.inputGeomarketHandler}/>
                            <div className="submit" type="submit" name="submit" onClick={this.handleSubmit}>Soumettre</div>
                        </form>
                    </div>
                </div>
            </section>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        modalShow : state.showModal,
        msg : state.msg,
        showError : state.showError,
        showLoading : state.showLoading,
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onCloseModal: (language) => dispatch({ type: 'CLOSE', value:language }),
        onShowMessage: (msg, error, language) => dispatch({ type: 'SHOW_MESSAGE', value : msg, error : error, language:language }),
        onShowLoading: (language) => dispatch({ type: 'SHOW_LOADING', language:language}),
        onChangeLanguage: (language) => dispatch({ type: language })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSolution);
