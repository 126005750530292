import React from "react";

const History = ({language}) => {
    
    const hideFrench = {
        display : (language === 'English') ? "none" : "block"
    }
    const hideEnglish = {
        display : (language === 'English') ? "block" : "none"
    }


    return ( 
        <div className="history">
            <div style={hideFrench}>
                <div className="title">Our story</div>
                <p>
                    At the rise of the internet era, Patrice Carrenard, Nicolas Xanthopoulos, and Dominique Tremblay saw the opportunity for innovative and engaging software solutions. Founded in 1996, Visicom Media quickly became a leading developer and pioneer of Internet application technologies that help businesses grow their brand online and reach worldwide while expanding revenue opportunities.
                </p>
                <p>
                    WebExpert was the first product that Visicom Media launched. It was the world’s first HTML web page editing software in French. Then came FTPExpert, a simplified file management, and transfer software, and GoGraph, a graphics and clipart resource website for the Web.
                </p>
                <p>
                    Visicom Media then changed course and decided to venture into the toolbar market. The idea of creating a toolbar to search for images on GoGraph led to the custom Lycos, NASDAQ, and Altavista toolbars. This new success redefined the company's strategies and gave birth to Dynamic Toolbar.
                </p>
                <p>
                    In 2013, Visicom Media acquired ManyCam, which is a virtual camera software that offers a lot of tools and gadgets to enhance live chats & live streams, and it was sold in 2022.
                </p>
                <p>
                    Among Visicom Media’s current array of solutions are MyStart and Search Solutions. 
                </p>
                <p>
                    MyStart combines beautiful and smart start pages with integrated paid searches, which generates revenue opportunities with every new tab while providing users with productivity tools and breathtaking images within their browsers.
                </p>
                <p>
                    As a trusted syndicator of Yahoo!’s Sponsored Search Ad Feed for almost a decade, Visicom Media leverages the power of Yahoo! Search and creates custom search solutions to generate high click-through revenue. 
                </p>
                <p>
                    After 25 years in the market, Visicom Media continues to pursue its mission statement, which is to communicate through innovation that engages customers, empowers employees, and forges businesses.
                </p>
                <p>
                    The future is bright for Visicom Media as its team strives for continued excellence under the leadership of the tech pioneers. Looking ahead, the company plans to continue creating highly engaging and innovative tools and software applications that enhance user experience and increase revenue opportunities. 
                </p>
                <p>
                    The keys to most successful businesses are brand awareness and expansive online reach. When a business increases awareness and revenue opportunities, it has the chance to grow exponentially. That’s how Visicom Media has helped some of the largest brands in the world of distribution, development, and marketing thrive over the 25 years it has been around.
                </p>
            </div> 
            <div style={hideEnglish}>
                <div className="title">Notre histoire</div>
                <p>
                À l’aube de l’avènement de l'ère Internet, Patrice Carrenard, Nicolas Xanthopoulos et Dominique Tremblay ont vu l'opportunité pour la création de solutions de logicielles innovants. Fondée en 1996, Visicom Media est rapidement devenu un pionnier en développement de technologies d'application web qui aident les entreprises à développer leur image de marque en ligne tout en élargissant leurs opportunités de revenus à travers le monde.
                </p>
                <p>
                    WebExpert a été le premier produit lancé par Visicom Media. C'était le seul logiciel d'édition de pages Web HTML au monde en français. Puis vinrent FTPExpert, un logiciel simplifié de gestion et de transfert de fichiers Web puis GoGraph, un site Web de ressources graphiques et clipart pour le Web.
                </p>
                <p>
                    Visicom Media change ensuite de direction s'aventure sur le marché des barres d'outils. L'idée de créer une barre d'outils pour rechercher des images sur GoGraph a conduit aux barres d'outils personnalisées Lycos, NASDAQ et Altavista. Ce nouveau succès redéfinit les stratégies de l'entreprise et donna naissance à Dynamic Toolbar.
                </p>
                <p>
                    En 2013, Visicom Media a acquis ManyCam, un logiciel de caméra virtuelle qui offre de nombreux outils et gadgets pour améliorer les clavardages en direct et les flux en direct, et il a été vendu en 2022.
                </p>
                <p>
                    Parmi la gamme actuelle de solutions de Visicom Media figurent MyStart et Search Solutions. MyStart combine des pages de démarrage attrayantes et intelligentes avec des recherches payantes intégrées, qui génèrent des opportunités de revenus à chaque nouvel onglet du fureteur tout en offrant aux utilisateurs des outils de productivité et des images à couper le souffle.
                </p>
                <p>
                    En tant que syndicateur de confiance du flux de recherche commanditée de Yahoo! depuis près d'une décennie, Visicom Media exploite la puissance de Yahoo! pour rechercher et créer des solutions de recherche personnalisées pour générer des revenus de clics élevés.
                </p>
                <p>
                    L'avenir est prometteur pour Visicom Media alors que son équipe s'efforce d'atteindre l'excellence continue sous la direction de ses pionniers de la technologie. Pour l'avenir, la société prévoit de continuer à créer des outils et des applications logicielles attrayants et innovants qui améliorent l'expérience utilisateur et augmentent les opportunités de revenus.
                </p>
                <p>
                    La clé du succès des entreprises les plus prospères est une notoriété de la marque, combinée avec une large portée en ligne. Dès lors celle-ci a la possibilité de croître de manière exponentielle. C'est ainsi que Visicom Media a aidé certaines des plus grandes marques du monde à prospérer au cours des 25 années d'existence à travers la distribution, le développement et le marketing.
                </p>
            </div>
        </div>
    );
}
 
export default History;