import React, { Component } from 'react';
import {connect } from 'react-redux'

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
          props : props
        };
    }

    openSearchSolution = () => {
        this.props.onOpenModal(this.props.language)
    }

    render(){
   
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }
        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }

        return(
            <div className="what-to-do-products-container" id="our_products">
                <div className="products-list" style={hideFrench}>
                    {/* <div className="product">
                        <div className="logo manycam"></div>
                        <div className="title">ManyCam</div>
                        <div className="description">With over 100M downloads, ManyCam is the go-to live video software and virtual webcam for professionals and individuals looking to enhance their video conferences, live streams, and online classes.</div>
                        <div className="learn_more bluecolored">
                            <a href="https://manycam.com">Learn more</a>
                        </div>
                        <div className="line"></div>
                    </div> */}
                    <div className="product">
                        <div className="logo mystart"></div>
                        <div className="title">MyStart</div>
                        <div className="description">MyStart combines beautiful and smart start pages with integrated paid searches. It generates revenue opportunities with every new tab while providing users with productivity tools and breathtaking images.</div>
                        <div className="learn_more bluecolored">
                            <a href="https://mystart.com">Learn more</a>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="product">
                        <div className="logo yahoo"></div>
                        <div className="title">Search Solutions</div>
                        <div className="description">As a trusted syndicator of Yahoo's Sponsored Search Ad Feed for more than a decade, Visicom Media leverages the power of Yahoo! Search and delivers customized solutions that generate high click-through revenue.</div>
                        <div className="learn_more bluecolored">
                            <span onClick={this.openSearchSolution}>Learn more</span>
                        </div>
                        <div className="line"></div>
                    </div>
                </div>
                <div className="products-list" style={hideEnglish}>
                    {/* <div className="product">
                        <div className="logo manycam"></div>
                        <div className="title">ManyCam</div>
                        <div className="description">Avec plus de 100 millions de téléchargements, ManyCam est le logiciel de vidéo en direct et la webcam virtuelle de référence pour les professionnels et les particuliers qui souhaitent améliorer la qualité de leurs visioconférences, de leurs diffusions en direct et de leurs cours en ligne.</div>
                        <div className="learn_more bluecolored">
                            <a href="https://manycam.com/?lang=fr">En savoir plus</a>
                        </div>
                        <div className="linefr"></div>
                    </div> */}
                    <div className="product">
                        <div className="logo mystart"></div>
                        <div className="title">MyStart</div>
                        <div className="description">MyStart combine des pages de démarrage élégantes et intelligentes avec des recherches payantes intégrées.Cette application génère des opportunités de revenus avec chaque nouvel onglet tout en fournissant aux utilisateurs des outils de productivité et des images à couper le souffle.</div>
                        <div className="learn_more bluecolored">
                            <a href="https://mystart.com">En savoir plus</a>
                        </div>
                        <div className="linefr"></div>
                    </div>
                    <div className="product">
                        <div className="logo yahoo"></div>
                        <div className="title">Search Solutions</div>
                        <div className="description">En tant que syndicateur de confiance du flux publicitaire de recherche sponsorisée de Yahoo! depuis plus d'une décennie, Visicom Media tire parti de la puissance de Yahoo! Search et fournit des solutions personnalisées qui génèrent d'importants bénéfices pour chaque clic.</div>
                        <div className="learn_more bluecolored">
                            <span onClick={this.openSearchSolution}>En savoir plus</span>
                        </div>
                        <div className="linefr"></div>
                    </div>
                </div>
            </div>
        )
    }
};
 
const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
        onOpenModal: (language) => dispatch({ type: 'OPEN', value: language})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);