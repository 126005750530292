import React from 'react';
import {Link} from "react-router-dom";
import Footer from './footer';
import ContentBody from './contentBody';

function Privacy() {
    const marginLeft = {
        marginLeft: '15px',
        width: '180px'
    }
    return (
            <>
            <div className="privacy">
                <div style={marginLeft}>
                    <Link to="/" className="navbar-brand-about">
                        <div className="logo dark"></div>
                    </Link>
                </div>
                <ContentBody page="privacy" />
            </div>
            <Footer page="privacy"/>
            </>
    );
}

export default Privacy;
