import React, { Component } from 'react';
import {connect } from 'react-redux';
import {Link} from "react-router-dom";

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
          website: props.website,
          props : props
        };
    }
    setCookie = (cname, cvalue, exdays) => {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue.toUpperCase() + ";" + expires + ";path=/";
    }
      
    getCookie = (cname) => {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    componentDidMount() {
        if(this.getCookie('language') !== "")
            this.props.onChangeLanguage(this.getCookie('language'));
        else
            this.props.onChangeLanguage('ENGLISH');
    }

    switchLanguage = (event, lg) => {
        event.preventDefault();
        if(lg === 'Français')
            this.props.onChangeLanguage('FRENCH');
        else
            this.props.onChangeLanguage('ENGLISH');  
            
        //Set cookie
        this.setCookie('language', lg, 1000);
    }

    render(){
        const logo = (this.props.logo === "dark" ? 'logo dark' : 'logo') ;
        const hamburgerColor = (this.props.logo ? 'animated-icon2 about' : 'animated-icon2') ;
        const navbarBrand = (this.props.logo === "dark" ? 'navbar-brand-about' : 'navbar-brand') ;
        const languageText = {
            position: "absolute",
            color: (this.props.logo === "dark") ? '#47566a' : '#47566a',
            fontSize: "13px",
            right:"100px",
            textDecoration:"none"
        }
    return ( 
        <nav className="navbar" style={{ position:"relative" }}>
            <Link to="/" className={navbarBrand}>
                <div className={logo}></div>
            </Link>
           
            <button className="second-button" type="button">
                <div className={hamburgerColor}><span></span><span></span><span></span><span></span></div>
            </button>
             <a href="/#" style={languageText} onClick={(e) => this.switchLanguage(e, this.props.language)}>{this.props.language}</a>
        </nav>
     );
    }
}
 
const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);