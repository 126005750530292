import React from 'react';
import {Route, Switch} from "react-router-dom";
import Home from './home';
import About from './about';
import Privacy from './privacy';
import Pressrelease from './pressrelease';
import MediaCSS from './media';

const Root = () => {
    return(
        <div>
            <MediaCSS>
                <Switch>
                    <Route component={Home} exact path="/" />
                    <Route component={About} path="/about" />
                    <Route component={Privacy} path="/privacy" />
                    <Route component={Pressrelease} path="/pressrelease/25thanniversary" />
                </Switch>
            </MediaCSS>
        </div>
    )
};

export default Root;